/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import focusLock from 'dom-focus-lock';
import AOS from 'aos';
import ResponsiveAutoHeight from 'responsive-auto-height';

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease'
});

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
	document.documentElement.style.setProperty('--hh', `${document.documentElement.clientHeight + 3}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Same height
if (document.querySelectorAll('.same-height').length) {
	new ResponsiveAutoHeight('.same-height');
}

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '2560px'
	});
}

// Hero slideshow 
var slideshow = document.querySelectorAll('.slideshow .swiper-container');
if (slideshow.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;
		var swiper = new Swiper(".slideshow .swiper-container", {
			grabCursor: false,

			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			loop: true,
			autoplay: {
				delay: 3000,
				disableOnInteraction: false
			},
			speed: 1500,
			noSwiping: true,
			allowTouchMove: false,
			noSwiping: true,
			noSwipingClass: 'swiper-slide'
		});
	})();
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Parallax overflow up
async function loadParallaxOverflowUp(images) {
	//await import('simple-parallax-js');
	const simpleParallax = await import('simple-parallax-js');

	new simpleParallax.default(images, {
		delay: .5,
		orientation: 'up',
		overflow: true,
		transition: 'cubic-bezier(0,0,0,1)'
	});
}
var images = document.querySelectorAll(".parallax-overflow-up,.parallax");
if (images !== null) {
	loadParallaxOverflowUp(images);
}

document.addEventListener('DOMContentLoaded', function () {
	var anchors = document.querySelectorAll("a");
	anchors.forEach(function (a, i) {
		var url = (a.href.split('#').length > 1) ? a.href.split('#')[0] : null;
		var anchor = (a.href.split('#').length > 1) ? a.href.split('#')[1] : null;

		if (anchor != null && (url == null || url == window.location.href.split('#')[0])) {

			a.addEventListener("click", function (e) {
				e.preventDefault();
				if (anchor) {
					var element = document.querySelector('#' + anchor);
					if (element) {
						var elPosY = element.getBoundingClientRect().top + document.documentElement.scrollTop;
						var scrollPosY = element.getBoundingClientRect().top + document.documentElement.scrollTop;
						window.scrollTo(0, scrollPosY);
					}
				}
			});
		}
	});
}, false);

// Parallax - overflow down
async function loadParallaxOverflowDown(images) {
	//await import('simple-parallax-js');
	const simpleParallax = await import('simple-parallax-js');

	new simpleParallax.default(images, {
		delay: .5,
		orientation: 'down',
		overflow: true,
		transition: 'cubic-bezier(0,0,0,1)'
	});
}
var images = document.getElementsByClassName('parallax-overflow-down');
if (images !== null) {
	loadParallaxOverflowDown(images);
}

// Milestone slider
var milestoneSliders = document.querySelectorAll('.content-block.is-milestone-block');
if (milestoneSliders.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		milestoneSliders.forEach(el => {

			//el.addEventListener("mouseenter", function (event) {
			//	console.log("mouse-entering");
			//}, false);

			//el.addEventListener("mouseover", function (event) {
			//	console.log("mouse-over");
			//}, false);

			const columnBlock = el.querySelector('.milestones'),
				swiperItem = columnBlock.querySelectorAll('.milestone'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div'),
				swiperButtons = document.createElement('div'),
				swiperPagination = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtons.setAttribute('class', 'swiper-buttons');
			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			swiperButtons.appendChild(swiperButtonNext);
			swiperButtons.appendChild(swiperButtonPrev);
			el.appendChild(swiperButtons);

			swiperPagination.setAttribute('class', 'swiper-pagination');
			el.appendChild(swiperPagination);

			var i = 0;
			var nextMilestone = swiperItem.lenght;
			swiperItem.forEach(si => {
				var swiperSlide = document.createElement('div');
				swiperSlide.setAttribute('class', 'swiper-slide');
				swiperSlide.appendChild(si);
				columnBlock.appendChild(swiperSlide);
				if (si.classList.contains('is-next-milestone')) {
					nextMilestone = i;
				}
				i++;
			});

			el.classList.add('has-slider');

			var milstoneSwiper = new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				},
				pagination: {
					el: swiperPagination,
					clickable: true
				},
				mousewheel: {
					releaseOnEdges: true,
					sensitivity: 1
				}
			});

			milstoneSwiper.slideTo(nextMilestone);
		});
	})();
}

// News slider
var newsSlider = document.querySelectorAll('.content-block.is-news-slider');
if (newsSlider.length) {
	(async () => {
		const Swiper = (await import('swiper')).default;

		newsSlider.forEach(el => {
			const columnBlock = el.querySelector('.news-messages'),
				swiperItem = columnBlock.querySelectorAll('.column'),
				swiperContainer = document.createElement('div'),
				swiperButtonPrev = document.createElement('div'),
				swiperButtonNext = document.createElement('div'),
				swiperButtons = document.createElement('div'),
				swiperPagination = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);

			swiperButtons.setAttribute('class', 'swiper-buttons');
			swiperButtonPrev.setAttribute('class', 'swiper-button-next');
			swiperButtonNext.setAttribute('class', 'swiper-button-prev');
			swiperButtons.appendChild(swiperButtonNext);
			swiperButtons.appendChild(swiperButtonPrev);
			swiperContainer.appendChild(swiperButtons);

			swiperPagination.setAttribute('class', 'swiper-pagination');
			el.appendChild(swiperPagination);

			swiperItem.forEach(si => {
				si.setAttribute('class', 'swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				keyboard: {
					enabled: true
				},
				navigation: {
					nextEl: swiperButtonPrev,
					prevEl: swiperButtonNext
				},
				pagination: {
					el: swiperPagination,
					clickable: true
				},
				spaceBetween: 48
			});
		});
	})();
}

// Jump to submitted form
document.querySelectorAll('.contact-form, .registration-form').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});

		var anchor = el.querySelector('.form').getAttribute('id');
		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);
	}
});

// Close menu on backdrop click, anchor click or escape key
document.querySelectorAll('.menu-checkbox').forEach(function (dropdown) {
	if (dropdown) {
		document.addEventListener('click', e => {
			if (!dropdown.contains(e.target) && (dropdown.checked) && (e.target.classList.contains("hamburger-inner") == false) && (e.target.classList.contains("hamburger") == false) && (e.target.classList.contains("hamburger-box") == false)) {
				toggle(false);
			}
		});

		var elements = document.getElementsByClassName("menu-link");

		for (var i = 0; i < elements.length; i++) {
			elements[i].style.transitionDelay = i*100+"ms";
			elements[i].addEventListener('click', e => {
				toggle(false);
			});
		}

		const toggle = checked => {
			dropdown.checked = checked;

			if (checked) {
				focusLock.on(dropdown);
			} else {
				focusLock.off(dropdown);
			}
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});
	}
});

var elements = document.getElementsByClassName("open-modal");

for (var i = 0; i < elements.length; i++) {
	elements[i].addEventListener('click', e => {
		var id = e.target.id;
		var modalId = "modal-" + id.split("-")[1];
		var modalToOpen = document.getElementById(modalId);
		if (modalToOpen) {
			modalToOpen.classList.remove("hidden");
		}
	});
}

let popUpNumber = 0;
var popUps = document.querySelectorAll('.is-pop-over');
popUps.forEach(function (popover) {
	if (popover) {
		var elements = popover.getElementsByClassName("close-modal");

		for (let i = 0; i < elements.length; i++) {
			elements[i].addEventListener('click', e => {
				toggle(e, popover);
			});
		}

		const popOver = document.getElementById('modal-' + popUpNumber),
			popOverContent = popOver.querySelector('.pop-over'),
			buttonPrev = document.createElement('div'),
			buttonNext = document.createElement('div');

		buttonPrev.setAttribute('class', 'pop-over-prev');
		buttonNext.setAttribute('class', 'pop-over-next');

		popOverContent.appendChild(buttonPrev);
		popOverContent.appendChild(buttonNext);

		(function (popover, index) {
			buttonPrev.addEventListener('click', e => {
				showPrev(e, popover, index);
			});

			buttonNext.addEventListener('click', e => {
				showNext(e, popover, index);
			});
		})(popover, popUpNumber);


		document.addEventListener('keydown', e => {
			if (e.keyCode === 27) {
				toggleOff(e, popover);
			}
		});

		popUpNumber++;
	}
});

function toggle(e, popover) {
	if (popover.classList.contains("hidden")) {
		popover.classList.remove("hidden");
	} else {
		popover.classList.add("hidden");
	}
};

function toggleOff(e, popover) {
	if (!popover.classList.contains("hidden")) {
		popover.classList.add("hidden");
	}
};

function showNext(e, current, i) {
	current.classList.add("hidden");
	var next = document.getElementById('modal-' + (i + 1));
	if (next == null) {
		next = document.getElementById('modal-0');
	}
	next.classList.remove("hidden");
}

function showPrev(e, current, i) {
	current.classList.add("hidden");
	var prev = document.getElementById('modal-' + (i - 1));
	if (prev == null) {
		prev = document.getElementById('modal-' + (popUps.length - 1));
	}
	prev.classList.remove("hidden");
}